import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/loginGuard.guard';

// Layouts
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { ModeratorLayoutComponent } from './layouts/moderator/moderator-layout.component';
import { StaffLayoutComponent } from './layouts/staff/staff-layout.component';
import { UserLayoutComponent } from './layouts/user/user-layout.component';

// Common
import { LogsPageModule } from './scenes/administrator-common/logs/logs/logs.module';
import { DoesnotExistPageModule } from './scenes/common/doesnot-exist/doesnot-exist.module';
import { ForgotPasswordModule } from './scenes/common/forgot-password/forgot-password.module';
import { IsAuthorizationModule } from './scenes/common/is-authorization/is-authorization.module';
import { LoginPageModule } from './scenes/common/login/login.module';
import { RecoverPasswordModule } from './scenes/common/recover-password/recover-password.module';
import { SearchContentModule } from './components/search-content/search-content.module';

// Administrator common
import { BroadcastPageModule } from './scenes/administrator-common/broadcast/broadcast/broadcast.module';
import { DashboardModule } from './scenes/administrator-common/dashboard/dashboard.module';
import { NotificationsModule } from './scenes/administrator-common/notifications/notifications.module';
import { HelpDeskModule } from './scenes/administrator-common/help-desk/help-desk/help-desk.module';
import { ChannelsPageModule } from './scenes/administrator-common/platinum-chat/channels/channels.module';
import { ToolsSettingModule } from './scenes/user/tools/trade-tools-setting/tools-setting/tools-setting.module';
import { ProductsPageModule } from './scenes/administrator-common/products/products/products.module';
import { ProductCommentPageModule } from './scenes/administrator-common/products/product-comment/product-comment.module';
import { SavedSearchesModule } from './scenes/administrator-common/saved-searches/saved-searches.module';
import { UsersPageModule } from './scenes/administrator-common/users/users/users.module';
import { SyncCronModule } from './scenes/administrator-common/crons/sync-cron/sync-cron.module';
// Admin
import { BackupPageModule } from './scenes/admin/backup/backup.module';

// User
import { UserDashboardPageModule } from './scenes/user/user-dashboard/user-dashboard.module';
import { PlatinumChatPageModule } from './scenes/user/platinum-chat/platinum-chat.module';
import { PagesPageModule } from './scenes/user/empty-group/empty-group.module';
import { ProductContentsPageModule } from './scenes/user/product-contents/product-contents.module';
import { NotificationsModule as UserNotificationsModule } from './scenes/user/notifications/notifications.module';
import { ChatPageModule } from './scenes/administrator-common/platinum-chat/chat/chat.module';
import { PlatformVariablesModule } from './scenes/administrator-common/platform-variables/platform-variables.module';
import { AdministratorProfilePageModule } from './scenes/administrator-common/profile/profile.module';
import { UserProfilePageModule } from './scenes/user/profile/profile.module';
import { ToolsModule } from './scenes/user/tools/tools.module';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./scenes/common/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [LoginGuard],
  },
  // {
  //   path: 'validate-magic-link',
  //   component: ValidateTokenComponent,
  //   canActivate: [LoginGuard],
  // },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./scenes/common/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'magic-link',
    loadChildren: () =>
      import('./scenes/common/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'verify-magic-link',
    loadChildren: () =>
      import('./scenes/common/validate-token/validate-token.module').then(
        (m) => m.ValidateTokenModule
      ),
  },
  {
    path: 'password-recover',
    loadChildren: () =>
      import('./scenes/common/recover-password/recover-password.module').then(
        (m) => m.RecoverPasswordModule
      ),
  },
  {
    path: '404',
    loadChildren: () =>
      import('./scenes/common/doesnot-exist/doesnot-exist.module').then(
        (m) => m.DoesnotExistPageModule
      ),
  },

  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRoles: ['admin'],
    },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./scenes/administrator-common/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./scenes/common/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./scenes/administrator-common/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./components/search-content/search-content.module').then(
            (m) => m.SearchContentModule
          ),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./scenes/administrator-common/products/products/products.module').then(
            (m) => m.ProductsPageModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./scenes/administrator-common/users/users/users.module').then(
            (m) => m.UsersPageModule
          ),
      },
      {
        path: 'quizzes',
        loadChildren: () =>
          import('./scenes/administrator-common/quizzes/quizzes.module').then(
            (m) => m.QuizzesModule
          ),
      },
      {
        path: 'help-desk',
        loadChildren: () =>
          import('./scenes/administrator-common/help-desk/help-desk/help-desk.module').then(
            (m) => m.HelpDeskModule
          ),
      },
      {
        path: 'chat',
        loadChildren: () =>
          import('./scenes/administrator-common/platinum-chat/channels/channels.module').then(
            (m) => m.ChannelsPageModule
          ),
      },
      {
        path: 'tools-setting',
        loadChildren: () =>
          import('./scenes/user/tools/trade-tools-setting/tools-setting/tools-setting.module').then(
            (m) => m.ToolsSettingModule
          ),
      },
      {
        path: 'climate',
        loadChildren: () =>
          import('./scenes/administrator-common/climate/climate.module').then(
            (m) => m.ClimateModule
          ),
      },
      {
        path: 'backup',
        loadChildren: () =>
          import('./scenes/admin/backup/backup.module').then((m) => m.BackupPageModule),
      },
      {
        path: 'logs',
        loadChildren: () =>
          import('./scenes/administrator-common/logs/logs/logs.module').then(
            (m) => m.LogsPageModule
          ),
      },
      {
        path: 'broadcast',
        loadChildren: () =>
          import('./scenes/administrator-common/broadcast/broadcast/broadcast.module').then(
            (m) => m.BroadcastPageModule
          ),
      },
      {
        path: 'saved-searches',
        loadChildren: () =>
          import('./scenes/administrator-common/saved-searches/saved-searches.module').then(
            (m) => m.SavedSearchesModule
          ),
      },
      {
        path: 'crons',
        loadChildren: () =>
          import('./scenes/administrator-common/crons/sync-cron/sync-cron.module').then(
            (m) => m.SyncCronModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./scenes/administrator-common/profile/profile.module').then(
            (m) => m.AdministratorProfilePageModule
          ),
      },
      {
        path: 'is-authorization',
        loadChildren: () =>
          import('./scenes/common/is-authorization/is-authorization.module').then(
            (m) => m.IsAuthorizationModule
          ),
      },
      {
        path: 'platinum-chat',
        loadChildren: () =>
          import('./scenes/administrator-common/platinum-chat/chat/chat.module').then(
            (m) => m.ChatPageModule
          ),
      },
      {
        path: 'platinum-chat/:setting/channel/:channel',
        loadChildren: () =>
          import('./scenes/administrator-common/platinum-chat/chat/chat.module').then(
            (m) => m.ChatPageModule
          ),
      },
      {
        path: 'platform-variables',
        loadChildren: () =>
          import('./scenes/administrator-common/platform-variables/platform-variables.module').then(
            (m) => m.PlatformVariablesModule
          ),
      },
      {
        path: '404',
        loadChildren: () =>
          import('./scenes/common/doesnot-exist/doesnot-exist.module').then(
            (m) => m.DoesnotExistPageModule
          ),
      },
      { path: '**', redirectTo: '404' },
    ],
  },
  {
    path: 'staff',
    component: StaffLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRoles: ['staff', 'admin'],
    },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./scenes/administrator-common/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./scenes/common/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./scenes/administrator-common/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./components/search-content/search-content.module').then(
            (m) => m.SearchContentModule
          ),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./scenes/administrator-common/products/products/products.module').then(
            (m) => m.ProductsPageModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./scenes/administrator-common/users/users/users.module').then(
            (m) => m.UsersPageModule
          ),
      },
      {
        path: 'logs',
        loadChildren: () =>
          import('./scenes/administrator-common/logs/logs/logs.module').then(
            (m) => m.LogsPageModule
          ),
      },
      {
        path: 'broadcast',
        loadChildren: () =>
          import('./scenes/administrator-common/broadcast/broadcast/broadcast.module').then(
            (m) => m.BroadcastPageModule
          ),
      },
      {
        path: 'saved-searches',
        loadChildren: () =>
          import('./scenes/administrator-common/saved-searches/saved-searches.module').then(
            (m) => m.SavedSearchesModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./scenes/administrator-common/profile/profile.module').then(
            (m) => m.AdministratorProfilePageModule
          ),
      },
      {
        path: 'is-authorization',
        loadChildren: () =>
          import('./scenes/common/is-authorization/is-authorization.module').then(
            (m) => m.IsAuthorizationModule
          ),
      },
      {
        path: 'platinum-chat',
        loadChildren: () =>
          import('./scenes/administrator-common/platinum-chat/chat/chat.module').then(
            (m) => m.ChatPageModule
          ),
      },
      {
        path: 'platinum-chat/:setting/channel/:channel',
        loadChildren: () =>
          import('./scenes/administrator-common/platinum-chat/chat/chat.module').then(
            (m) => m.ChatPageModule
          ),
      },
      {
        path: 'platform-variables',
        loadChildren: () =>
          import('./scenes/administrator-common/platform-variables/platform-variables.module').then(
            (m) => m.PlatformVariablesModule
          ),
      },
      {
        path: '404',
        loadChildren: () =>
          import('./scenes/common/doesnot-exist/doesnot-exist.module').then(
            (m) => m.DoesnotExistPageModule
          ),
      },
      { path: '**', redirectTo: '404' },
    ],
  },
  {
    path: 'moderator',
    component: ModeratorLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRoles: ['moderator', 'admin'],
    },
    children: [
      { path: '', redirectTo: 'products/comments', pathMatch: 'full' },
      { path: 'dashboard', redirectTo: 'products/comments', pathMatch: 'full' },
      {
        path: 'products/comments',
        loadChildren: () =>
          import(
            './scenes/administrator-common/products/product-comment/product-comment.module'
          ).then((m) => m.ProductCommentPageModule),
      },
      {
        path: 'products/comments/:productGroupSlug/:productSlug',
        loadChildren: () =>
          import(
            './scenes/administrator-common/products/product-comment/product-comment.module'
          ).then((m) => m.ProductCommentPageModule),
      },
      {
        path: 'products/comments/:productGroupSlug/product/:productSlug/category/:categorySlug/content/:contentSlug',
        loadChildren: () =>
          import(
            './scenes/administrator-common/products/product-comment/product-comment.module'
          ).then((m) => m.ProductCommentPageModule),
      },
      {
        path: 'products/comments/:productGroupSlug/product/:productSlug/category/:categorySlug/content/:contentSlug/sub-content/:subContentSlug',
        loadChildren: () =>
          import(
            './scenes/administrator-common/products/product-comment/product-comment.module'
          ).then((m) => m.ProductCommentPageModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./scenes/administrator-common/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: 'platinum-chat',
        loadChildren: () =>
          import('./scenes/administrator-common/platinum-chat/chat/chat.module').then(
            (m) => m.ChatPageModule
          ),
      },
      {
        path: 'platinum-chat/:setting/channel/:channel',
        loadChildren: () =>
          import('./scenes/administrator-common/platinum-chat/chat/chat.module').then(
            (m) => m.ChatPageModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./scenes/administrator-common/profile/profile.module').then(
            (m) => m.AdministratorProfilePageModule
          ),
      },
      {
        path: '404',
        loadChildren: () =>
          import('./scenes/common/doesnot-exist/doesnot-exist.module').then(
            (m) => m.DoesnotExistPageModule
          ),
      },
      { path: '**', redirectTo: '404' },
    ],
  },
  {
    path: 'user',
    component: UserLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRoles: ['user', 'admin', 'staff'],
    },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./scenes/user/user-dashboard/user-dashboard.module').then(
            (m) => m.UserDashboardPageModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./scenes/common/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./scenes/user/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./components/search-content/search-content.module').then(
            (m) => m.SearchContentModule
          ),
      },
      {
        path: 'platinum-chat',
        loadChildren: () =>
          import('./scenes/user/platinum-chat/platinum-chat.module').then(
            (m) => m.PlatinumChatPageModule
          ),
      },
      {
        path: 'platinum-chat/:setting/channel/:channel',
        loadChildren: () =>
          import('./scenes/user/platinum-chat/platinum-chat.module').then(
            (m) => m.PlatinumChatPageModule
          ),
      },
      {
        path: 'product-groups/:product-group',
        pathMatch: 'full',
        loadChildren: () =>
          import('./scenes/user/empty-group/empty-group.module').then((m) => m.PagesPageModule),
      },
      {
        path: 'product-groups/:productGroup/product/:product',
        pathMatch: 'full',
        loadChildren: () =>
          import('./scenes/user/product-contents/product-contents.module').then(
            (m) => m.ProductContentsPageModule
          ),
      },
      {
        path: 'product-groups/:productGroup/product/:product/category/:category',
        pathMatch: 'full',
        loadChildren: () =>
          import('./scenes/user/product-contents/product-contents.module').then(
            (m) => m.ProductContentsPageModule
          ),
      },
      {
        path: 'product-groups/:productGroup/product/:product/category/:category/content',
        pathMatch: 'full',
        loadChildren: () =>
          import('./scenes/user/product-contents/product-contents.module').then(
            (m) => m.ProductContentsPageModule
          ),
      },
      {
        path: 'product-groups/:productGroup/product/:product/category/:category/content/:content',
        pathMatch: 'full',
        loadChildren: () =>
          import('./scenes/user/product-contents/product-contents.module').then(
            (m) => m.ProductContentsPageModule
          ),
      },
      {
        path: 'product-groups/:productGroup/product/:product/category/:category/content/:content/sub-content/:subContent',
        pathMatch: 'full',
        loadChildren: () =>
          import('./scenes/user/product-contents/product-contents.module').then(
            (m) => m.ProductContentsPageModule
          ),
      },
      {
        path: 'product-groups/:productGroup/product/:product/result/:shortCode/:index',
        pathMatch: 'full',
        loadChildren: () =>
          import('./scenes/user/review-result/review-result.module').then(
            (m) => m.ReviewResultModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./scenes/user/profile/profile.module').then((m) => m.UserProfilePageModule),
      },
      {
        path: '404',
        loadChildren: () =>
          import('./scenes/common/doesnot-exist/doesnot-exist.module').then(
            (m) => m.DoesnotExistPageModule
          ),
      },
      { path: 'settings', loadChildren: () => UserProfilePageModule },
      { path: 'tools', loadChildren: () => ToolsModule },
      { path: '404', loadChildren: () => DoesnotExistPageModule },
      { path: '**', redirectTo: '404' },
    ],
  },
  { path: '**', redirectTo: '404' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
