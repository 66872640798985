<ion-content class="ion-padding" appScrollbar>
  <ol class="cd-breadcrumb custom-separator custom-icons" *ngIf="!hideCryptoDetails">
    <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
    <li>
      <a routerLink="/{{ role }}/tools/setup-scanner/{{ activeToolSlug }}">Setup-Scanner</a>
    </li>
    <li class="current">{{ setupScannerRow?.name }}</li>
  </ol>

  <ol class="cd-breadcrumb custom-separator custom-icons" *ngIf="hideCryptoDetails">
    <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
    <li class="current">Upcoming Earnings</li>
  </ol>
  <div class="page-heading" *ngIf="hideCryptoDetails">
    <span> Upcoming Earnings</span>
  </div>

  <div class="page-heading" *ngIf="!hideCryptoDetails">
    <span class="back-trade" (click)="backToSetupScanner()"> < Back to Setup Scanner</span>
  </div>
  <div class="setupscanner-main-wrap">
    <ion-grid class="icon-grid-block crypto step-scanner" *ngIf="hideCryptoDetails">
      <ion-col class="cryptos">
        <ion-card class="crypto-info">
          <ion-row>
            <ion-col class="setup-scanner-dropdown" [ngClass]="{ 'show-filter': isFilterActive }">
              <ion-searchbar
                debounce="1000"
                search-icon="none"
                id="search"
                class="search ion-no-padding setup-search"
                (ionClear)="onClearSearch()"
                (ionChange)="onSearch($event)"
                placeholder="Search by Ticker Symbol "
              >
              </ion-searchbar>
              <ion-button fill="outline" (click)="openRightMenu()">
                <ion-icon slot="start" name="options-outline"></ion-icon>
                Filters
                <span class="number-round" *ngIf="filterCountShow">{{
                  filterCountShow == 0 ? '' : filterCountShow
                }}</span>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-grid>
    <ion-grid class="icon-grid-block crypto step-scanner" *ngIf="!hideCryptoDetails">
      <ion-col class="cryptos">
        <ion-card class="crypto-info">
          <div class="crypto-details">
            <div class="crypto-details-info">
              <span class="show-watchlist-name">{{ setupScannerRow?.name }}</span>
              <p class="show-description">{{ setupScannerRow?.description }}</p>
            </div>
            <div class="crypto-details-button">
              <div class="input-container">
                <ion-item class="ion-item-no-inner-padding hover-bg">
                  <ion-select
                    [(ngModel)]="saveValue"
                    line="none"
                    placeholder="Save"
                    okText="Okay"
                    cancelText="Dismiss"
                    interface="popover"
                    class="custom-select"
                    (ionChange)="onSelectChange($event)"
                  >
                    <ion-select-option value="saveCurrentPreset" [disabled]="CurrentPresets"
                      >Save Current Preset</ion-select-option
                    >
                    <ion-select-option value="saveNewPreset">Save as New Preset</ion-select-option>
                  </ion-select>
                </ion-item>
              </div>
              <ion-button
                class="color-css1"
                fill="outline"
                (click)="createEditSetupScanner('edit')"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.9429 3.69113L17.309 6.05721M16.464 1.60669L10.0632 8.00752C9.73248 8.33779 9.50693 8.75857 9.41497 9.21683L8.82373 12.1764L11.7833 11.584C12.2415 11.4924 12.6618 11.2677 12.9926 10.9369L19.3934 4.53608C19.5858 4.34373 19.7383 4.11538 19.8424 3.86407C19.9465 3.61276 20.0001 3.3434 20.0001 3.07139C20.0001 2.79937 19.9465 2.53001 19.8424 2.2787C19.7383 2.02739 19.5858 1.79904 19.3934 1.60669C19.2011 1.41435 18.9727 1.26177 18.7214 1.15767C18.4701 1.05358 18.2007 1 17.9287 1C17.6567 1 17.3874 1.05358 17.136 1.15767C16.8847 1.26177 16.6564 1.41435 16.464 1.60669Z"
                    stroke="#777777"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.7649 14.4119V17.7649C17.7649 18.3577 17.5294 18.9263 17.1102 19.3455C16.691 19.7647 16.1224 20.0002 15.5296 20.0002H3.23532C2.64247 20.0002 2.07391 19.7647 1.65471 19.3455C1.23551 18.9263 1 18.3577 1 17.7649V5.47067C1 4.87782 1.23551 4.30926 1.65471 3.89006C2.07391 3.47086 2.64247 3.23535 3.23532 3.23535H6.58829"
                    stroke="#777777"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ion-button>
              <ion-button class="color-css1" fill="outline" (click)="deleteSetupScanner()">
                <svg
                  width="19"
                  height="21"
                  viewBox="0 0 19 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5 21C2.85833 21 2.30922 20.7717 1.85267 20.3152C1.39611 19.8586 1.16744 19.3091 1.16667 18.6667V3.5H0V1.16667H5.83333V0H12.8333V1.16667H18.6667V3.5H17.5V18.6667C17.5 19.3083 17.2717 19.8578 16.8152 20.3152C16.3586 20.7725 15.8091 21.0008 15.1667 21H3.5ZM15.1667 3.5H3.5V18.6667H15.1667V3.5ZM5.83333 16.3333H8.16667V5.83333H5.83333V16.3333ZM10.5 16.3333H12.8333V5.83333H10.5V16.3333Z"
                    fill="#777777"
                  />
                </svg>
              </ion-button>
            </div>
          </div>
          <ion-row>
            <ion-col class="setup-scanner-dropdown" [ngClass]="{ 'show-filter': isFilterActive }">
              <ion-searchbar
                debounce="1000"
                search-icon="none"
                id="search"
                class="search ion-no-padding setup-search"
                (ionClear)="onClearSearch()"
                (ionChange)="onSearchCategory($event, 'search')"
                placeholder="Search by Ticker Symbol"
              >
              </ion-searchbar>
              <ion-button fill="outline" (click)="openRightMenu()">
                <ion-icon slot="start" name="options-outline"></ion-icon>
                Filters Settings
                <span class="number-round" *ngIf="filterCountShow">{{
                  filterCountShow == 0 ? '' : filterCountShow
                }}</span>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-grid>
    <div>
      <div class="loading-spinner" *ngIf="isLoading"></div>
      <ngx-datatable
        #externalSpendTable
        *ngIf="!isLoading"
        class="user-view material setup-scanner-table"
        (page)="applyFilterList($event.offset)"
        [rows]="rows"
        [columnMode]="'force'"
        [rowHeight]="'45'"
        [headerHeight]="50"
        [footerHeight]="40"
        [externalPaging]="true"
        [scrollbarH]="true"
        [count]="page.totalElements"
        [offset]="page.offset"
        [limit]="page.size"
      >
        <ngx-datatable-column
          [width]="60"
          [sortable]="false"
          [resizeable]="false"
          [frozenLeft]="true"
        >
          <ng-template ngx-datatable-header-template>TICKER</ng-template>
          <ng-template ngx-datatable-cell-template let-symbol="row.symbol">
            <span [ngClass]="{ 'green-symbol': true }" (click)="viewSymbol(symbol)">
              {{ symbol }}
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="270" [sortable]="false" [resizeable]="false">
          <ng-template ngx-datatable-header-template>COMPANY</ng-template>
          <ng-template ngx-datatable-cell-template let-company="row.company">
            {{ company }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="200" [sortable]="false" [resizeable]="false">
          <ng-template ngx-datatable-header-template>INDUSTRY</ng-template>
          <ng-template ngx-datatable-cell-template let-industry="row.industry">
            {{ industry }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="200" [sortable]="false" [resizeable]="false">
          <ng-template ngx-datatable-header-template>SECTOR</ng-template>
          <ng-template ngx-datatable-cell-template let-sector="row.sector">
            {{ sector }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [headerClass]="'wfifty'"
          [cellClass]="'wfifty'"
          [width]="70"
          [width]="100"
          [sortable]="false"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>
            <div class="sorting-data" (click)="sortDataMarketCap()">
              <div class="sort-arrows">
                <ion-icon name="caret-up-outline"></ion-icon>
                <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
              </div>
              <span>MARKET CAP</span>
            </div>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-marketCap="row.marketCap">
            {{ marketCap }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [headerClass]="'wfifty'"
          [cellClass]="'wfifty'"
          [width]="30"
          [width]="60"
          [sortable]="false"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>
            <div class="sorting-data" (click)="sortDataPrice()">
              <div class="sort-arrows">
                <ion-icon name="caret-up-outline"></ion-icon>
                <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
              </div>
              <span>PRICE</span>
            </div></ng-template
          >
          <ng-template ngx-datatable-cell-template let-regularMarketPrice="row.regularMarketPrice">
            {{ regularMarketPrice }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [cellClass]="getHeaderClassChangePercent"
          [width]="70"
          [resizeable]="false"
          [sortable]="false"
          [headerClass]="'wfifty'"
          [cellClass]="'wfifty'"
        >
          <ng-template ngx-datatable-header-template>
            <div class="sorting-data" (click)="sortDataChange()">
              <div class="sort-arrows">
                <ion-icon name="caret-up-outline"></ion-icon>
                <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
              </div>
              <span>CHANGE</span>
            </div></ng-template
          >
          <!-- <ng-template
            ngx-datatable-cell-template
            let-regularMarketChangePercent="row.regularMarketChangePercent"
          >
            <span
              [ngClass]="{
                'text-red': regularMarketChangePercent < 0,
                'text-green': regularMarketChangePercent > 0
              }"
              >{{ regularMarketChangePercent | number : '1.2-2' }}%
            </span>
          </ng-template> -->

          <ng-template ngx-datatable-cell-template let-changePercent="row.changePercent">
            <span
              [ngClass]="{
                'text-red': changePercent < 0,
                'text-green': changePercent > 0
              }"
              >{{ changePercent | number : '1.2-2' }}%
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [headerClass]="'wfifty'"
          [cellClass]="'wfifty'"
          [width]="70"
          [sortable]="false"
          [width]="100"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>
            <div class="sorting-data" (click)="sortDataVolume()">
              <div class="sort-arrows">
                <ion-icon name="caret-up-outline"></ion-icon>
                <ion-icon class="icon-arrow" name="caret-down-outline"></ion-icon>
              </div>
              <span>VOLUME</span>
            </div></ng-template
          >
          <ng-template
            ngx-datatable-cell-template
            let-regularMarketVolume="row.regularMarketVolume"
          >
            {{ regularMarketVolume }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [sortable]="false"
          [resizeable]="false"
          [cellClass]="'cell-custom-class'"
        >
          <ng-template ngx-datatable-header-template> ACTION </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="relative">
              <ion-buttons>
                <ion-button fill="clear" (click)="toggleDropdown($event, row)">
                  <ion-icon name="ellipsis-vertical"> </ion-icon>
                </ion-button>
              </ion-buttons>
              <div *ngIf="isDropdownOpen && selectedRow === row" class="dropdown-menu">
                <ion-item lines="none" (click)="addToWatchlist(row.symbol)" id="watchlist-list-area"
                  >Add to Watchlist
                </ion-item>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</ion-content>
