<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content">
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <img class="app-logo header-logo" src="assets/icon/team-hub-logo.png" alt="" />
        </ion-toolbar>
      </ion-header>

      <ion-content appScrollbar>
        <div *ngFor="let p of getSortedPages()">
          <!-- normal menu without childrens -->
          <ion-menu-toogle *ngIf="p.url">
            <ion-item
              class="parent-menu"
              lines="none"
              (click)="p.callback && p.callback()"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="p.url"
              routerDirection="root"
              routerLinkActive="active"
              detail="false"
            >
              <ng-container *ngIf="p.icon.includes('/icons'); else defaultIconTemplate">
                <!-- <img class="quize" [src]="p.icon" /> -->
                <span *ngIf="p.icon.includes('/quiz-icon.svg')">
                  <svg
                    class="quize"
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1185 0H0.517944V22H17.4824V4.36396L13.1185 0ZM13.3581 1.39624L16.0862 4.12437H13.3581V1.39624ZM1.33583 21.1821V0.817884H12.5402V4.94226H16.6646V21.1822L1.33583 21.1821Z"
                      fill="#72B642"
                    />
                    <path
                      d="M3.53125 6.41107H6.53997V3.40234H3.53125V6.41107ZM4.34913 4.22023H5.72209V5.59318H4.34913V4.22023Z"
                      fill="#72B642"
                    />
                    <path
                      d="M3.53125 10.4736H6.53997V7.46484H3.53125V10.4736ZM4.34913 8.28273H5.72209V9.65568H4.34913V8.28273Z"
                      fill="#72B642"
                    />
                    <path
                      d="M3.53125 14.5361H6.53997V11.5273H3.53125V14.5361ZM4.34913 12.3452H5.72209V13.7182H4.34913V12.3452Z"
                      fill="#72B642"
                    />
                    <path
                      d="M3.53125 18.5986H6.53997V15.5898H3.53125V18.5986ZM4.34913 16.4077H5.72209V17.7807H4.34913V16.4077Z"
                      fill="#72B642"
                    />
                    <path d="M13.9502 17.7812H7.69678V18.5991H13.9502V17.7812Z" fill="#72B642" />
                    <path d="M13.9502 13.7188H7.69678V14.5366H13.9502V13.7188Z" fill="#72B642" />
                    <path d="M13.9502 9.65625H7.69678V10.4741H13.9502V9.65625Z" fill="#72B642" />
                    <path d="M13.9502 5.59375H7.69678V6.41163H13.9502V5.59375Z" fill="#72B642" />
                  </svg>
                </span>
                <!-- <img [src]="p.icon" [ngClass]="{'active': isActive(p.url), 'inactive': !isActive(p.url)}" /> -->
              </ng-container>
              <ng-template #defaultIconTemplate>
                <ion-icon [name]="p.icon" slot="start"></ion-icon>
              </ng-template>

              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toogle>
          <!-- menu without url and childrens -->
          <ion-menu-toogle *ngIf="!p.url && !p.childrens">
            <ion-item
              class="parent-menu"
              lines="none"
              button
              (click)="p.callback && p.callback()"
              detail="false"
            >
              <ng-container *ngIf="p.icon.includes('/icons'); else defaultIconTemplate">
                <img [src]="p.icon" />
              </ng-container>
              <ng-template #defaultIconTemplate>
                <ion-icon [name]="p.icon" slot="start"></ion-icon>
              </ng-template>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toogle>
          <!-- menu with childrens -->
          <ion-item
            class="parent-menu"
            lines="none"
            button
            *ngIf="p.childrens?.length > 0"
            (click)="p.open = !p.open; p.callback && p.callback()"
            [class.active-parent]="p.open"
            detail="false"
          >
            <ng-container *ngIf="p.icon.includes('/icons'); else defaultIconTemplate">
              <img [src]="p.icon" />
            </ng-container>
            <ng-template #defaultIconTemplate>
              <ion-icon [name]="p.icon" slot="start"></ion-icon>
            </ng-template>
            <ion-label>{{ p.title }}</ion-label>
            <ion-icon name="chevron-forward-outline" size="small" *ngIf="!p.open"></ion-icon>
            <ion-icon name="chevron-down-outline" size="small" *ngIf="p.open"></ion-icon>
            <!-- channel update dot -->
            <div *ngIf="p.slug && p.slug === 'chat' && !p.open">
              <span [ngClass]="{ 'update-channels-bedge': channelUpdates === true }"></span>
            </div>
          </ion-item>
          <!-- childrens -->
          <ion-list *ngIf="p.open" class="ion-no-padding">
            <ion-menu-toogle>
              <ion-item
                class="sub-item"
                [ngClass]="{
                  'spend-item': sub.title === 'Spend',
                  'website-category-item': sub.title === 'Category',
                  'lead-source-item': sub.title === 'Lead Source'
                }"
                *ngFor="let sub of p.childrens"
                lines="none"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="sub.url"
                routerDirection="root"
                routerLinkActive="active"
                detail="false"
              >
                <ion-label>{{ sub.title }}</ion-label>
                <!-- channel message count -->
                <ion-badge
                  *ngIf="sub.source && sub.source.count && sub.source.count > 0"
                  class="bedge"
                  color="primary"
                  slot="end"
                >
                  {{ sub.source.count }}</ion-badge
                >
              </ion-item>
            </ion-menu-toogle>
          </ion-list>
        </div>
      </ion-content>
      <div class="footer-logo">
        <h6>Powered By</h6>
        <img class="app-logo" src="assets/icon/tradersedgenetwork-transparent.png" alt="" />
        <h6 class="app-version">Version : {{ appVersion }}</h6>
      </div>
    </ion-menu>

    <div class="ion-page" id="main-content">
      <app-common></app-common>
      <ion-content>
        <ion-router-outlet> </ion-router-outlet>
      </ion-content>
    </div>
  </ion-split-pane>

  <ion-menu side="end" class="alert-menu-width" contentId="main-content">
    <ion-content class="ion-padding">
      <app-common-right-menu></app-common-right-menu>
    </ion-content>
  </ion-menu>
</ion-app>
