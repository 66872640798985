import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ModalController, Platform } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AppGalleryComponent } from 'src/app/components/gallery/gallery.component';
import { InAppNotificationComponent } from 'src/app/components/in-app-notification/in-app-notifications.component';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { ChannelTypeService } from 'src/app/services/channel-type.service';
import { ChatService } from 'src/app/services/chat.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SettingService } from 'src/app/services/setting.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from '../../../environments/environment';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  public appVersion = environment.version;
  public subscriptions = [];
  public channels: any = [];
  public channelUpdates = false;
  public userId: any = null;
  public isChatActive = false;
  public routeBaseRole = 'admin';

  public pages: any = [
    {
      title: 'Dashboard',
      url: `/${this.routeBaseRole}/dashboard`,
      icon: 'grid-outline',
      sortOrder: 1,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Users',
      url: `/${this.routeBaseRole}/users`,
      icon: 'people-outline',
      sortOrder: 2,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Products',
      url: `/${this.routeBaseRole}/products`,
      icon: 'cart-outline',
      sortOrder: 3,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Quizzes',
      url: `/${this.routeBaseRole}/quizzes`,
      icon: '../../../assets/icons/quiz-icon.svg',
      sortOrder: 5,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Help-Desk',
      url: `/${this.routeBaseRole}/help-desk`,
      icon: 'help-circle-outline',
      sortOrder: 6,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Chat Setting',
      url: `/${this.routeBaseRole}/chat`,
      icon: 'chatbubbles-outline',
      sortOrder: 7,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Tools Setting',
      url: `/${this.routeBaseRole}/tools-setting`,
      icon: 'hammer-outline',
      sortOrder: 7,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Insider Max',
      icon: 'trending-up-outline',
      childrens: [
        {
          title: 'Treasure Hunting Climate',
          url: `/${this.routeBaseRole}/climate`,
        },
        {
          title: 'Crew Insights',
        },
      ],
      sortOrder: 7,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Comment Moderation',
      url: `/${this.routeBaseRole}/products/comments`,
      icon: 'chatbox-outline',
      sortOrder: 8,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Gallery Manager',
      icon: 'library-outline',
      sortOrder: 9,
      callback: this.openGalleryManager.bind(this),
    },
    {
      title: 'Backups',
      url: `/${this.routeBaseRole}/backup`,
      icon: 'server-outline',
      sortOrder: 10,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Saved Searches',
      url: `/${this.routeBaseRole}/saved-searches`,
      icon: 'reader-outline',
      sortOrder: 11,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Broadcast',
      url: `/${this.routeBaseRole}/broadcast`,
      icon: 'radio-outline',
      sortOrder: 12,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Platform Variables',
      url: `/${this.routeBaseRole}/platform-variables`,
      icon: 'code-slash-outline',
      sortOrder: 13,
      callback: this.generalCallback.bind(this),
    },
    {
      title: 'Logs',
      icon: 'trending-up-outline',
      childrens: [
        {
          title: 'Server',
          url: `/${this.routeBaseRole}/logs/server`,
        },
        {
          title: 'Broadcast Email',
          url: `/${this.routeBaseRole}/logs/broadcast/email`,
        },
        {
          title: 'Broadcast SMS',
          url: `/${this.routeBaseRole}/logs/broadcast/text`,
        },
      ],
      sortOrder: 14,
      callback: this.generalCallback.bind(this),
    },
  ];

  constructor(
    public storageServ: StorageService,
    private router: Router,
    private notificationService: NotificationService,
    private platform: Platform,
    private chatService: ChatService,
    private broadcastService: BroadcastService,
    private settingServ: SettingService,
    private channelService: ChannelTypeService,
    private tradeToolsService: TradeToolsService,
    private ngxToastr: ToastrService,
    public modalController: ModalController,
    private toolsApi: ToolServiceService
  ) {
    this.userId = this.storageServ.get('userId');
    this.configureOnesignal();

    const user = this.storageServ.getObject('userData');

    if (environment.cronSidebarView) {
      this.pages.push({
        title: 'Crons',
        icon: 'sync-outline',
        childrens: [
          {
            title: 'Analytics',
            url: `/${this.routeBaseRole}/crons/analytics`,
          },
          {
            title: 'Broadcast',
            url: `/${this.routeBaseRole}/crons/broadcast`,
          },
          {
            title: 'Backup',
            url: `/${this.routeBaseRole}/crons/backup`,
          },
          {
            title: 'Droplet',
            url: `/${this.routeBaseRole}/crons/droplet`,
          },
          {
            title: 'Template',
            url: `/${this.routeBaseRole}/crons/template`,
          },
        ],
        sortOrder: 14,
        callback: this.generalCallback.bind(this),
      });
    }

    if (user && user.hasAnalyticsAccess) {
      this.pages.push({
        title: 'Reports',
        icon: 'bar-chart-outline',
        childrens: [
          {
            title: 'Dashboard',
            url: `/${this.routeBaseRole}/reports/dashborad`,
          },
          {
            title: 'LTV Report',
            url: `/${this.routeBaseRole}/reports/ltv-report`,
          },
          {
            title: 'Analytics',
            url: `/${this.routeBaseRole}/reports/analytics`,
          },
          {
            title: 'Spend',
            url: `/${this.routeBaseRole}/reports/spend`,
          },
          {
            title: 'Category',
            url: `/${this.routeBaseRole}/reports/website-category`,
          },
          {
            title: 'Lead Source',
            url: `/${this.routeBaseRole}/reports/lead-source`,
          },
        ],
        sortOrder: 4,
        callback: this.generalCallback.bind(this),
      });
    }
  }

  configureOnesignal() {
    this.platform
      .ready()
      .then(() => {
        if (Capacitor.getPlatform() !== 'web') {
          this.broadcastService.configureNativePush();
        } else {
          this.broadcastService.configureWebPush();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ngOnInit(): void {
    this.clearChannelData();
    this.prepareChatRoutes();

    if (this.userId) {
      this.chatService.establishSocketConnection(this.userId);
      this.notificationService.establishSocketConnection(this.userId);
    }

    const channelCountUpdateSubscription = this.chatService
      .onChannelCountUpdate()
      .subscribe((counts: any) => {
        if (counts.length && this.channels) {
          /* tslint:disable */
          for (let i = 0; i < counts.length; i++) {
            const channelCount = counts[i];
            const channelIndex = this.channels.findIndex(
              (c: any) => c._id === channelCount.channelId
            );
            if (channelIndex !== -1) {
              this.channels[channelIndex].count = channelCount.count;
            }
          }

          if (this.channels.some((c) => c.count && c.count > 0) && !this.isChatActive) {
            this.channelUpdates = true;
          } else {
            this.channelUpdates = false;
          }
        }
      });

    // In-app notification listener
    const newNotificationSubscription = this.notificationService
      .onNewInAppNotification()
      .subscribe((notification: any) => {
        if (notification && Capacitor.getPlatform() == 'web') {
          const notificationInstance = new NotificationsComponent(
            this.notificationService,
            this.storageServ,
            this.router,
            null,
            this.toolsApi
          );

          const [notificationFormated] = notificationInstance.formatNotifications([notification]);
          if (notificationFormated) {
            this.presentInAppNotification(notificationFormated);
          }
        }
      });

    this.subscriptions.push(channelCountUpdateSubscription, newNotificationSubscription);
  }

  presentInAppNotification(notification) {
    let inAppTitle = '';

    if (notification.type === 'ChatComment') {
      inAppTitle = 'Post Comment';
    } else if (notification.type === 'Broadcast') {
      inAppTitle = 'Broadcast';
    } else if (notification.type === 'InsiderChat') {
      inAppTitle = 'Insider Room';
    }

    const toastRef = this.ngxToastr.success('', inAppTitle, {
      toastComponent: InAppNotificationComponent,
      timeOut: 30000,
      extendedTimeOut: 6000,
      enableHtml: true,
      newestOnTop: true,
      progressBar: true,
      progressAnimation: 'increasing',
      closeButton: true,
      easing: 'ease-in',
      easeTime: 300,
      positionClass: 'toast-bottom-left',
      // @ts-ignore
      payload: notification,
    });

    toastRef.onTap.pipe(take(1)).subscribe(() => {
      if (notification) {
        const { url, queryParams } = notification.hrefLink;

        this.notificationService.markAsReadNotification(notification.id);

        this.router.navigate([`${url}`], {
          queryParams,
          queryParamsHandling: 'merge',
        });
      }
    });

    toastRef.onAction.subscribe(() => {
      this.ngxToastr.clear(toastRef.toastId);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  async openGalleryManager() {
    const modal = await this.modalController.create({
      component: AppGalleryComponent,
      cssClass: 'gallery-manager-modal',
    });

    return await modal.present();
  }

  clearChannelData() {
    this.storageServ.remove('channelId');
  }

  generalCallback() {
    this.storageServ.remove('channelId');
    this.isChatActive = false;
  }

  chatMenuCallback() {
    this.isChatActive = true;
  }

  getSortedPages = () => this.pages.sort((a, b) => a.sortOrder - b.sortOrder);

  async prepareChatRoutes() {
    try {
      const [chatSettingPromise, chatChannelsPromise] = await Promise.all([
        this.settingServ.getAllSetting(),
        this.channelService.getAllChannelTypes(),
      ]);

      const getChatChannels = async (parentSlug): Promise<any> => {
        return new Promise((resolve) => {
          chatChannelsPromise.subscribe((channelsResponse: any) => {
            let { channels = [] } = channelsResponse.data;
            this.channels = channels;
            this.chatService.pingOnChannelsLoaded();

            let channelRoutes = channels.map((channel: any) => {
              return {
                title: channel.title,
                url: `/${this.routeBaseRole}/platinum-chat/${parentSlug}/channel/${channel.slug}`,
                source: channel,
              };
            });
            resolve(channelRoutes);
          });
        });
      };

      chatSettingPromise.subscribe(async (chatSettingResponse: any) => {
        if (chatSettingResponse) {
          const {
            setting: [chatSetting = null],
          } = chatSettingResponse.data;

          if (chatSetting) {
            const childrens = await getChatChannels(chatSetting.slug);
            this.pages.push({
              title: chatSetting.title,
              slug: chatSetting.slug,
              icon: chatSetting.icon,
              childrens,
              sortOrder: 6,
              source: chatSetting,
              callback: this.chatMenuCallback,
            });
          }
        }
      });
    } catch (error) {}
  }

  async prepareToolRoutes() {
    try {
      const [chatSettingPromise, chatChannelsPromise] = await Promise.all([
        this.settingServ.getAllSetting(),
        this.tradeToolsService.getAllToolTypes(),
      ]);

      const getChatChannels = async (parentSlug): Promise<any> => {
        return new Promise((resolve) => {
          chatChannelsPromise.subscribe((channelsResponse: any) => {
            let { channels = [] } = channelsResponse.data;
            this.channels = channels;
            this.chatService.pingOnChannelsLoaded();

            let channelRoutes = channels.map((channel: any) => {
              return {
                title: channel.title,
                url: `/${this.routeBaseRole}/platinum-chat/${parentSlug}/channel/${channel.slug}`,
                source: channel,
              };
            });
            resolve(channelRoutes);
          });
        });
      };

      chatSettingPromise.subscribe(async (chatSettingResponse: any) => {
        if (chatSettingResponse) {
          const {
            setting: [chatSetting = null],
          } = chatSettingResponse.data;

          if (chatSetting) {
            const childrens = await getChatChannels(chatSetting.slug);
            this.pages.push({
              title: chatSetting.title,
              slug: chatSetting.slug,
              icon: chatSetting.icon,
              childrens,
              sortOrder: 6,
              source: chatSetting,
              callback: this.chatMenuCallback,
            });
          }
        }
      });
    } catch (error) {}
  }
}
